const en = {
    // USAGE: i18next.t([key, fallback_key (default)])

    deviation: {
        NMF: {
            common: {
                org_id: 'Org. ID',
                org_name: 'Org. name',
            },

            pages: {
                home: 'Organizations',
            },
            organizations: {
                columnHeaders: {
                    org_id: 'Org. ID',
                    org_name: 'Org. name',
                },
            },

            organization: {
                headers: {
                    label: {
                        members: 'Members',
                    },
                },

                tabs: {
                    organization: 'Organization',
                    agreements: 'Agreements',
                },

                org_name: 'Org. name',

                send_email: {
                    labels: {
                        org_email: 'Organizations email',
                        org_invoice_email: 'Organizations email',
                    },
                },
            },
        },
        natteravn: {
            common: {
                org_id: 'Org. ID',
                org_name: 'Org. name',
            },

            pages: {
                home: 'Organizations',
            },
            organizations: {
                columnHeaders: {
                    org_id: 'Org. ID',
                    org_name: 'Org. name',
                },
            },

            organization: {
                headers: {
                    label: {
                        members: 'Members',
                    },
                },

                tabs: {
                    organization: 'Organization',
                    agreements: 'Agreements',
                },
                org_name: 'Org. name',

                send_email: {
                    labels: {
                        org_email: 'Organizations email',
                        org_invoice_email: 'Organizations email',
                    },
                },
            },
        },
        default: {
            common: {
                org_id: 'Org. ID',
                org_name: 'Org. name',
            },

            pages: {
                home: 'Organizations',
                'files-shared': 'Public files',
                'files-private': 'Private files',
            },
            organizations: {
                columnHeaders: {
                    org_id: 'Org. ID',
                    org_name: 'Org. name',
                },
            },

            organization: {
                headers: {
                    label: {
                        members: 'Members',
                    },
                },

                tabs: {
                    organization: 'Organization',
                    agreements: 'Agreements',
                },

                org_name: 'Org. name',

                send_email: {
                    labels: {
                        org_email: 'Organizations email',
                        org_invoice_email: 'Organizations email',
                    },
                },
            },
        },
    },

    common: {
        information: 'Information',
        open_link: 'Open link',
        copy_link: 'Copy link',
        copy: 'Copy',
        this_lang: 'English',
        language: 'Language',
        lists: 'Lists',
        country: 'Country',
        log_out: 'Log Out',
        none_selected: 'None selected',
        comment: 'Comment',
        save: 'Save',
        loading: 'Loading',
        update: 'Update',
        delete: 'Delete',
        cancel: 'Cancel',
        back: 'Back',
        select: 'Select',
        submit: 'Submit',
        are_you_sure: 'Are you sure you want to cancel?',
        app_access: 'App Access',
        you_dont_have_access:
            'You do not have access to this app.\nContact Styreportalen AS at support@styreportalen.no',
        search: 'Search',
        edit: 'Edit',
        members: 'Members',
        parents: 'Parents',
        reply: 'Reply',
        children: 'Children',
        force: 'Force',
        send: 'Send',
        yes: 'Yes',
        no: 'No',
        title: 'Title',
        upload: 'Upload',
        name: 'Name',
        you_dont_have_notes: 'You dont have access to any notesets in this project.',
        not_found: '{item} could not be found!',
        organization: 'Organization',
        read_past_tense: 'Read',
        compose: 'Compose',
        missing: 'Missing',
        of: 'Of',
    },
    login: {
        login: 'Login',
        sign_in: 'Sign in',
        email_address: 'Email Address',
        password: 'Password',
    },
    firebase_error: {
        'auth/internal-error': 'Error, please try again.',
        'auth/invalid-phone-number': 'Error, please re-enter phone number',
        'auth/invalid-email': 'Error, invalid email',
        'auth/user-not-found': 'User does not exist',
        'auth/wrong-password': 'Error, wrong password',
        'auth/invalid-verification-code': 'Error wrong sms code, try again',
        'login/invalid-input': 'Error, invalid input',
    },
    organizations: {
        columnHeaders: {
            org_id: 'Org. id',
            org_nr: 'Org. number',
            org_name: 'Org. name',
            founding_date: 'Founding date',
            mainStatus: 'Main status',
            status: 'Status',
            bank_account: 'Bank account',
            'address.address': 'Address',
            'address.city': 'City',
            'address.municipality_name': 'Municipality name',
            'address.municipality_number': 'Municipality number',
            'address.county': 'County',
            'address.post_code': 'Post code',
            'contact.email': 'Email',
            'contact.invoice_email': 'Invoice email',
            'custom_fields.Drilltype': 'Drilltype',
            'custom_fields.Besetning': 'Besetning',
            'custom_fields.Korpstype': 'Type',
            region: 'Region',
            members: 'Member count',
            has_portal: 'Has portal',
        },
        default_background_url:
            'https://firebasestorage.googleapis.com/v0/b/styreportalen-drift.appspot.com/o/Div%2FNMF%2FTopp_Portal-min.png?alt=media&token=1a64bd4a-7fbf-4d6a-9bd7-ef94588872e9',
        default_profile_url:
            'https://firebasestorage.googleapis.com/v0/b/styreportalen-drift.appspot.com/o/Div%2FNMF%2Fdemoikon_nmf.png?alt=media&token=b7686443-67a7-417e-9246-592a465c68c7',
        default_slogan: 'Alle snakker korps',
        tabs: {
            homepage: 'Home',
            board: 'Board',
            organization: 'Organization',
            members: 'Members',
            internal_notes: 'Internal notes',
            agreements: 'Agreements',
        },
        previous_board_members: 'previous board members',
        active_board: 'Active board',
        membership_statuses: {
            A: 'Active',
            O: 'Inactive',
        },
        members: {
            columnHeaders: {
                personId: 'Membership number',
                fullName: 'Name',
                membershipStatus: 'Status (membership)',
                membershipType: 'Membership type',
                primaryEmail: 'Email',
                secondaryEmail: 'Secondary email',
                mobile: 'Mobile',
                phone: 'Phone',
                city: 'City',
                group: 'Group',
                enrolled_date: 'Enrolled date',
                instrument: 'Instrument',
                insurance: 'Insurance',
                postCode: 'Postal code',
                primaryParentName: 'Primary parent name',
                secondaryParentName: 'Secondary parent name',
            },
            category: {
                1: 'Musician',
                2: 'Aspirant',
                3: 'Drills',
                4: 'Board',
                5: 'Instructor',
                6: 'Drill aspirant',
                7: 'Non-executive NMF member',
                9: 'Non NMF member',
            },
            insurance: {
                M: 'With instrument',
                U: 'Without instrument',
                I: 'Non',
            },
        },
    },
    forms: {
        adult_education: {
            adult_education_code: 'Adult Education Code',
            start_date_range: 'From date',
            end_date_range: 'To date',
            activity: 'Activity',
            created: 'Created',
            day_count: 'Day count',
            exam: 'Exam',
            finished_date: 'Finished Date',
            hours_with_instructor: 'Hours with instructor',
            instructor_name: 'Instructor Name',
            priority_funds: 'Priority funds',
            report_finished_date: 'Report Finished Date',
            settled_amount: 'Settled Amount',
            special_allowance: 'Special allowance',
            status_label: 'Status',
            time_of_day_label: 'Time of day',
            status: {
                NOT_FILLED: 'Not Filled',
                PROCESSING: 'Processing',
                FILLED: 'Filled',
                SENT: 'Sent',
                APPROVED: 'Approved',
                REJECTED: 'Rejected',
                CONTROL: 'Control',
            },
            time_of_day: {
                BOTH: 'Both',
                DAY: 'Day',
                NIGHT: 'Night',
            },
        },
    },
    pages: {
        invoices: 'Invoices',
        events: 'Events',
        about: 'About this app',
        feedback: 'Feedback',
        projects: 'Projects',
        profile: 'Account',
        select_organization: 'Select Organization',
        compose_feed: 'Compose Feed',
        messages: 'Messages',
        parents: 'Parents',
        notifications: 'Notifications',
        qr: 'QR',
        settings: 'Settings',
        // In use
        home: 'Organizations',
        members: 'Members',
        calendar: 'Calendar',
        messagecenter: 'Messagecenter',
        newsletter: 'Newsletter',
        setup: 'Setup',
        regions: 'Regions',
        users: 'Users',
        additionalAgreements: 'Additional Agreements',
        applicationcenter: 'Application Center',
        adult_education: 'Adult Education',
        mail_accounts: 'Mail Account',
        support: 'Support',
        files: 'File archive',
        'files-private': 'Files',
        'files-shared': 'Public shared files',
    },
    file_archive: {
        title: 'File archive',
        shared_title: 'Shared files',
        shared_description: 'Files are visible for {org}',
        private_title: 'Private files',
        private_description: 'Files are only visible in central',
        loading_message: 'Loading',
        processing_message: 'Proccessing changes',
        upload: 'Upload',
        upload_files: 'Upload files',
        create_folder: 'Create folder',
        rename_file: 'Rename file',
        rename_folder: 'Rename folder',
        delete_file: 'Delete file',
        delete_files: 'Delete files',
        delete_folder: 'Delete folder',
        delete_folders: 'Delete folders',
        download: 'Download',
        selectedForUpload: 'files selected for upload',
        filename: 'File name',
        filepath: 'File path',
        chosen_folder: 'Chosen folder:',
        upload_button: 'Upload files',
        upload_prompt: {
            title: 'Are you sure you want to upload files?',
            text: 'This will upload selected file to filearchive.',
        },
        delete_prompt: {
            title: 'Delete?',
            delete_files: 'You are about to delete all files selected! \nPlease confirm if you would like to proceed',
            delete_file: 'You are about to delete the selected file! \n\nPlease confirm if you would like to proceed',
            delete_folders:
                'You are about to delete several folders and all files they contained! \nPlease confirm you would like to proceed',
            delete_folder:
                'You are about to delete selected folder and all files contained! \nPlease confirm you would like to proceed',
        },
        control_prompt: {
            title: 'Duplicate filename',
            text: 'Overwrite or make copy?',
            input_text: 'Do for all',
            confirm_text: 'Overwrite',
            deny_text: 'Make copy',
        },
        upload_modal: {
            title: 'Upload files to',
            drop_zone: 'Drag and drop folders or files here, or click on the field to select files',
            default_alert:
                'WARNING!, Extremely large files or deep file structures might cause your browser to crash.\nTo prevent this separate your upload into smaller chunks if this happens',
            error_alert: 'Something went wrong with uploading of',
            upload_alert: 'Uploading file',
            view_button: 'View files',
            reselect_button: 'Select files again',
        },
    },
    profile: {
        membership: {
            category: {
                musician: 'Musician',
                aspirant: 'Aspirant',
                drill: 'Drill',
                board: 'Board position',
                conductor_instructor: 'Conductor/Instructor',
                drillaspirant: 'Drillaspirant',
                non_performing_nmf_member: 'Non performing NMF member',
                not_nmf_member: 'Not NMF member',
            },
            'membership-fields': {
                forsikring: 'Insurance',
                contingent: 'Contingent',
            },
            insurance: {
                u: 'No instrument',
                m: 'With instrument',
                i: 'No insurance',
            },
            department: 'Department',
            group: 'Group',
            secondary_group: 'Secondary Group',
            instrument_id: 'Instrument',
            member_category: 'Member Category',
            membership_id: 'Membership Id',
            person_id: 'Person Id',
            enrolled_date: 'Enrolled Date',
            membership: 'Membership',
        },
        other_fields: 'Other',
        org_defined_fields: 'Custom fields',
        children: 'Children',
        parents: 'Parents',
        group: 'Group',
        department: 'Department',
        instrument_group: 'Instrument Group',
        secondary_group: 'Secondary Group',
        saving: 'Saving',
        select_department: 'Select Department',
        select_group: 'Select Group',
        select_group_2: 'Select Secondary Group',
        label_none: 'None',
        first_name: 'First Name',
        last_name: 'Last Name',
        birth_date: 'Birth Date',
        gender: 'Gender',
        status: 'Status',
        active: 'Active',
        inactive: 'Inactive',
        address: 'Address',
        address_2: 'Address 2',
        post_code: 'Postal code',
        city: 'City',
        place: 'Place',
        personalia: 'Personalia',
        contact_info: 'Contact',
        phone: 'Phone',
        mobile: 'Mobile',
        email: 'Email',
        email_2: 'Email 2',
        birthday: 'Birthday',
        genders: {
            i: 'Unknown',
            k: 'Female',
            m: 'Male',
        },
    },
    support: {
        support: 'Support',
        title: 'Title',
        description: 'Description',
        error: 'Something went wrong:',
        missing_fields: 'Please fill out all fields before submitting!',
        inquiry_received: 'Your inquiry has been received and will be responded to as soon as possible.',
        create_inquiry: 'Create support ticket',
        read_documentation: 'Documentation for Styreportalen:',
    },
    utility: {
        CREATED: 'Created',
        SHOW_MORE: 'Show more',
        SAVE_BUTTON: 'Save',
        CANCEL_BUTTON: 'Cancel',
        CLOSE_BUTTON: 'Close',
        REQUIRED: 'Required',
        SENT: 'Sent',
        SEND_INQUIRY: 'Submit',
        NONE_SELECTED: 'None selected',
    },
    components: {
        members: {
            COLUMN_HEADERS: {
                ADDRESS: 'Address',
                ADDRESS_2: 'Address 2',
                AGE: 'Age',
                BIRTHDAY: 'Birthday',
                CHILDREN: 'Children',
                CITY: 'City',
                CONTINGENT: 'Contingent',
                DATE: 'Registration date',
                DEPARTMENT: 'Department',
                EMAIL: 'Email',
                EMAIL_2: 'Email 2',
                ENDED_DATE: 'End date',
                FAMILIES: 'Families',
                FIRST_NAME: 'First name',
                GROUP: 'Group',
                INSTRUMENT: 'Instrument',
                INSURANCE: 'Insurance',
                LAST_NAME: 'Last name',
                MEMBER_CATEGORY: 'Member category',
                MEMBERSHIP_TYPE: 'Membership type',
                MOBILE: 'Mobile',
                PARENT_1_EMAIL: 'Email (parent 1)',
                PARENT_1_MOBILE: 'Mobile (parent 1)',
                PARENT_1_NAME: 'Name (parent 1)',
                PARENT_2_EMAIL: 'Email (parent 2)',
                PARENT_2_MOBILE: 'Mobile (parent 2)',
                PARENT_2_NAME: 'Name (parent 2)',
                PERSON_ID: 'Membership number',
                PERSON_STATUS: 'Status',
                POST_CODE: 'Post code',
                SECONDARY_GROUP: 'Secondary group',
                TELEPHONE: 'Telephone',
            },
            CUSTOM_FIELDS: 'Custom fields',
            FIRST_NAME: 'First name',
            GENDER_OPTIONS: {
                FEMALE: 'Female',
                MALE: 'Male',
            },
            MEMBER: 'Member',
            MEMBER_CATEGORY: {
                aspirant: 'Aspirant',
                board: 'Board/office',
                drill: 'Drill',
                drill_aspirant: 'Drillaspirant',
                instructor: 'Conductor/instructor',
                musician: 'Musician',
                non_executive_NMF_member: 'Non-executive NMF-member',
                non_NMF_member: 'Non NMF-member',
                other: 'Other',
            },
            MEMBERS: 'Members',
            OTHERS: 'Other people',
            PARENT: 'Parent',
            PARENTS: 'Parents',
            PERSON: 'Person',
            PERSON_STATUS: {
                ACTIVE: 'Active',
                ENDED: 'Ended',
                RETIRED: 'Retired',
                TEMPORARY: 'Temporary',
                UNKNOWN: 'Unknown',
            },
            PERSONS: 'Persons',
            REQUIRED_FIELDS: 'Required fields',
            TABLE_PROPERTIES: {
                CHECKED_BUTTON: {
                    active_members: 'Show only active members',
                    active_persons: 'Show only active persons',
                    curr_and_previous_parents: 'Show past and present parents',
                    curr_parents: 'Show only current parents',
                    inactive_members: 'Also show retired members',
                    inactive_persons: 'Also show retired people',
                },
                CUSTOM_FIELDS_BUTTON: {
                    column_headers: {
                        field: 'Field',
                        type: 'Type',
                        values: 'Values',
                    },
                    delete: 'Delete',
                    fieldtype_options: {
                        long_text: 'Long text',
                        predefined_tag: 'Tag (predefined)',
                        single_choice: 'Single choice',
                        tag: 'Tag',
                        text: 'Text',
                    },
                    header: 'Manage custom fields',
                    header_2: 'Manage custom field',
                    inputs: {
                        fieldname: 'Field name',
                        fieldtype: 'Field type',
                        description: 'Description',
                        values: 'Values (empty fields will be deleted when you save)',
                    },
                    no_custom_fields: 'No custom fields exist yet',
                    save: 'Save',

                    sweetalert: {
                        text: 'Are you sure you want to remove this field?',
                    },
                },
                FILEEXPORT_BUTTON: {
                    header: 'Select export format',
                    tooltip_title: 'File export',
                },
                members: 'Members',
                Members_document_name: 'Memberslist',
                others: 'Other peoples',
                Others_document_name: 'Other_people_list',
                parents: 'Parents',
                Parents_document_name: 'Parentslist',
                status_active: 'Active',
                status_previous: 'Previous',
            },
        },
    },
};

export default en;
